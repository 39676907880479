<template>
    <div>
        <b-card>
            <b-row class="">
                <b-col class="col-md-12">
                    <h2 class="text-blue font-weight-bold col-md-10 col-lg-10 col-xl-11 mt-1">
                        {{ $t("send_email") }}
                    </h2>
                </b-col>
            </b-row>
        </b-card>

        <b-card>
            <h2>Invoices</h2>


            <b-select style="width: 20%" class="my-2" v-model="checkInvoicePeriod" @change="selectInvoicePeriod">
                <option value="all">All invoices</option>
                <option value="new">New invoices</option>
            </b-select>

            <BTable
                :items="invoices"
                striped
                :url="invoiceURL"
                responsive
                hover
                class="position-relative mt-2"
                show-empty
                :fields="invoicesFields"
            >
                <template #cell(check)="data">
                    <b-form-checkbox
                        v-model="invoiceCheckboxes[data.index].isChecked">
                    </b-form-checkbox>
                    <!--v-model="invoiceCheckboxes[data.index].isChecked" @change="toggleInvoiceCheckbox">-->
                </template>
            </BTable>
        </b-card>

        <b-card>
            <h2>Investors</h2>


            <BTable
                :items="investors"
                striped
                :url="investorURL"
                responsive
                hover
                class="position-relative mt-2"
                show-empty
                :fields="investorsFields"
            >

                <template #head(check)="data" class="d-flex">

                    <b-form-checkbox @change="changeAllCheckboxes()">{{$t('check')}}</b-form-checkbox>

                </template>

                <template #cell(check)="data">

                    <b-form-checkbox
                        v-model="investorCheckboxes[data.index].isChecked">
                    </b-form-checkbox>
                    <!--v-model="investorCheckboxes[data.index].isChecked" @change="toggleInvestorCheckbox">-->

                </template>
            </BTable>
        </b-card>
        <b-button @click="collectCheckedItems">{{ $t('send_email') }}</b-button>
    </div>
</template>

<script>

  import {
    BTable,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BPagination,
    BTr,
    BTh,
    BButton,
    BLink,
  } from "bootstrap-vue";
  import CustomLoader from "@/components/Common/CustomLoader";
  import Table from "@/components/Common/Table2";
  import Ripple from "vue-ripple-directive";
  import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

  export default {
    components: {
      Table,
      BTable,
      BRow,
      BCol,
      BFormGroup,
      BFormInput,
      BFormCheckbox,
      BPagination,
      BTr,
      BTh,
      BButton,
      BLink,
      CustomLoader,
    },

    directives: {
      Ripple,
    },
    computed: {
      rows() {
        return this.totalItems;
      },
    },

    data() {
      return {

        allCheckboxes: false,

        invoicePage: 1,
        investorPage: 1,

        invoiceTotalItems: 0,
        investorTotalItems: 0,

        checkInvoicePeriod: "new",

        invoiceURL: '/daily_email/get_invoice',
        investorURL: '/daily_email/get_investors',

        invoices: null,
        investors: null,

        
        uncheckedInvestors: null,
        uncheckedInvestorsID: [],

        invoiceCheckboxes: [],
        investorCheckboxes: [],

        invoicesFields: [
          {
            key: "id",
            label: "id",
          },
          {
            key: "title",
            label: "title",
          },
          {
            key: "check",
            label: "check",
          },
        ],

        investorsFields: [
          {
            key: "id",
            label: "id",
          },
          {
            key: "name",
            label: "name",
          },
          {
            key: "last_name",
            label: "last_name",
          },
          {
            key: "check",
            label: "check",
          },
        ],
      };
    },

    created() {
      this.getInvoice();
      this.getInvestor()
    },

    methods: {

      changeAllCheckboxes() {
        this.allCheckboxes = !this.allCheckboxes
        this.investorCheckboxes.forEach(checkbox => {
          checkbox.isChecked = this.allCheckboxes;
        });

      },

      selectInvoicePeriod() {
        console.log(this.checkInvoicePeriod);
        this.getInvoice()
      },
      getInvoice() {
        this.$http
            .post(`/daily_email/get_invoice?page=` + this.page, {
              'invoice_period': this.checkInvoicePeriod
            })
            .then((res) => {
              if (res) {
                this.invoices = res.data.value.data;
                this.invoiceTotalItems = res.data.value.total;
                this.invoicePage = res.data.value.current_page;

                this.invoiceCheckboxes = this.invoices.map(invoice => ({id: invoice.id, isChecked: true}));
              }
            });
      },

      getInvestor() {
        this.$http
            .post(`/daily_email/get_investors?page=` + this.page)
            .then((res) => {
              if (res) {

                this.investors = res.data.value.investors.data;
                this.uncheckedInvestors = res.data.value.unchecked_investors?.data;
                this.investorTotalItems = res.data.value.investors.total;
                this.investorPage = res.data.value.investors.current_page;


                if(this.uncheckedInvestors != null) {
                  this.uncheckedInvestors.forEach(el => {
                    this.uncheckedInvestorsID.push(el.id)
                  });

                  this.investors.forEach(investor => {
                    const isChecked = !this.uncheckedInvestorsID.includes(investor.id);
                    this.investorCheckboxes.push({ id: investor.id, isChecked });
                  });
                }else {
                  this.investors.forEach(investor => {
                    const isChecked = true;
                    this.investorCheckboxes.push({ id: investor.id, isChecked });
                  });

                }

                // this.investorCheckboxes = this.investors.map(investor => ({id: investor.id, isChecked: true}));
                // this.investorCheckboxes = this.investors.map(investor => ({
                //   id: investor.id,
                //   isChecked: !this.uncheckedInvestors.includes(investor.id)
                // }));
              }
            });
      },


      // toggleInvoiceCheckbox(...e) {
      //   this.invoiceCheckboxes = this.invoiceCheckboxes.map(el => {
      //     if (id === el.id) {
      //       return { ...el, isChecked: !el.isChecked };
      //     }
      //     return el;
      //   });
      // },
      //
      // toggleInvestorCheckbox(...e) {
      //   this.investorCheckboxes = this.investorCheckboxes.map(el => {
      //     if (id === el.id) {
      //       return { ...el, isChecked: !el.isChecked };
      //     }
      //     return el;
      //   });
      // },

      collectCheckedItems() {
        const checkedInvoiceItems = this.invoiceCheckboxes
            .filter(checkbox => checkbox.isChecked)
            .map(checkbox => checkbox.id);

        const checkedInvestorItems = this.investorCheckboxes
            .filter(checkbox => checkbox.isChecked)
            .map(checkbox => checkbox.id);

        const uncheckedInvestorItems = this.investorCheckboxes
            .filter(checkbox => !checkbox.isChecked)
            .map(checkbox => checkbox.id);

        const requestBody = {
          "invoices" : checkedInvoiceItems,
          "unchecked_investors" : uncheckedInvestorItems,
          "checked_investors" : checkedInvestorItems
        };

        this.$http
            .post(`/daily_email/send_email`, requestBody)
            .then((res) => {
              if (res) {
                // this.investors = res.data.value.investors.data;
                // this.uncheckedInvestors = res.data.value.unchecked_investors;
                // this.investorTotalItems = res.data.value.investors.total;
                // this.investorPage = res.data.value.investors.current_page;
                //
                // this.investorCheckboxes = this.investors.map(investor => ({id: investor.id, isChecked: true}));

                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: this.$t(
                        "email_send_successfully"
                    ),
                    icon: "SuccessIcon",
                    variant: "success",
                  },
                });

              }
            });

      }

    }

  }

</script>

<style scoped>

</style>